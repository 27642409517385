import React, { memo, useContext, useState } from "react";

import { Text, LanguageCtx } from '../context/LanguageCtx'

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Image from '../components//Image';

import { Box, Button } from '@material-ui/core';

import '../assets/css/aboutus.scss'

function AboutUsPage() {
  const { getText } = useContext(LanguageCtx);
  const [isShowCV, setShowCV] = useState(false)

  return(
      <Layout headerType={1}>
        <SEO title={getText("aboutUs")} />
        <Box id="about-us-page" className="page content-light content-padd-top padd-btm-dbl">
          <h3 className='page-title push-top-dbl padd-top-dbl'><Text tid="aboutUs"/></h3>
          <p className='page-title-text push-btm push-top padd-hlf padd-btm-hlf padd-top-hlf'>
            <Text tid="aboutUs.preText"/>
          </p>
          <Box className="page-content t-center">
            <Image alt="Vták ohnivák - poster" filename="profilPicture.jpg" />
            <h1>JÁN ĎUROVČÍK</h1>
            <Image alt="Ján Ďurovčík" filename="profilPicture2.jpg" />
            <Box className={`push-top push-btm-hlf padd-hlf curiculum ${isShowCV ? "active" : ""}`}>
              <Text tid="aboutUs.CV1"/>
              <Text tid="aboutUs.CV2"/>
            </Box>
          </Box>
          {!isShowCV &&
            <Box className='t-center padd-btm push-btm push-top-dbl'>
              <Button className='btn btn-1' onClick={() => setShowCV(true)}><Text tid="aboutUs.viewall"/></Button>
            </Box>
          }
        </Box>
      </Layout>
  );   
}
export default memo(AboutUsPage);